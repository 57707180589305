<template>
  <div>
    <v-row class="match-height">
      <v-col cols="12">
        <v-card>
          <v-card-title>Video Dashboard</v-card-title>
          <v-card-text>
            <v-form ref="form" lazy-validation class="multi-col-validation">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete outlined dense v-model="item.tranding" :items="init.video_categories" item-text="name"
                    item-value="item_id" :menu-props="{ maxHeight: '400' }" label="Tranding" multiple persistent-hint
                    deletable-chips small-chips chips></v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete outlined dense v-model="item.multiselect" :items="init.video_categories"
                    item-text="name" item-value="item_id" :menu-props="{ maxHeight: '400' }" label="Upcoming" multiple
                    persistent-hint deletable-chips small-chips chips></v-autocomplete>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field v-model="item.title" label="Title 1" outlined dense placeholder="Title 1" hide-details>
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete outlined dense v-model="item.greeting" :items="init.video_categories" item-text="name"
                    item-value="item_id" :menu-props="{ maxHeight: '400' }" multiple :label="item.title" persistent-hint
                    deletable-chips small-chips chips></v-autocomplete>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field v-model="item.title2" label="Title 2" outlined dense placeholder="Title 2" hide-details>
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete outlined dense v-model="item.dailypost" :items="init.video_categories"
                    item-text="name" item-value="item_id" :menu-props="{ maxHeight: '400' }" :label="item.title2"
                    multiple persistent-hint deletable-chips small-chips chips></v-autocomplete>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select outlined dense v-model="item.cat_1" :items="categoriesList" item-text="name" item-value="id"
                    label="Home Category 1"></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select outlined dense v-model="item.cat_2" :items="categoriesList" item-text="name" item-value="id"
                    label="Home Category 2"></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select outlined dense v-model="item.cat_3" :items="categoriesList" item-text="name" item-value="id"
                    label="Home Category 3"></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select outlined dense v-model="item.cat_4" :items="categoriesList" item-text="name" item-value="id"
                    label="Home Category 4"></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select outlined dense v-model="item.cat_5" :items="categoriesList" item-text="name" item-value="id"
                    label="Home Category 5"></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select outlined dense v-model="item.cat_6" :items="categoriesList" item-text="name" item-value="id"
                    label="Home Category 6"></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select outlined dense v-model="item.cat_7" :items="categoriesList" item-text="name" item-value="id"
                    label="Home Category 7"></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select outlined dense v-model="item.cat_8" :items="categoriesList" item-text="name" item-value="id"
                    label="Home Category 8"></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select outlined dense v-model="item.cat_9" :items="categoriesList" item-text="name" item-value="id"
                    label="Home Category 9"></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select outlined dense v-model="item.cat_10" :items="categoriesList" item-text="name"
                    item-value="id" label="Home Category 10"></v-select>
                </v-col>

                <v-col cols="12">
                  <v-btn @click="submit" color="primary"> Submit </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiMagnify, mdiPencilOutline } from '@mdi/js'
import { serialize } from 'object-to-formdata'
export default {
  name: 'VideoDash',
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiPencilOutline,
      },
    }
  },
  data() {
    return {
      item: {},
      categoriesList: [],
      rules: {
        required: value => !!value || 'Required.',
      },
    }
  },
  computed: {
    init() {
      return this.$store.getters['Init/init']
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      setTimeout(() => {
        this.categoriesList = this.init.video_categories;
        this.categoriesList.unshift({ id: null, name: 'Select Category' });
        this.item = this.init.video_dashboard;
        this.item.tranding = this.item.tranding.split(',')
        this.item.multiselect = this.item.multiselect.split(',')
        this.item.greeting = this.item.greeting.split(',')
        this.item.dailypost = this.item.dailypost.split(',')
      }, 1500);
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$api
          .post('video-setting', this.item)
          .then(response => {
            this.$toast.info(response.message)
            this.$router.push({ name: 'dashboard' })
          })
      }
    },
  },
}
</script>
